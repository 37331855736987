import React from "react";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";

import { useDocument } from "../../useFirebase";
import { ManifestRow, Manifest } from "./types";

function TableRow({ row }: { row: ManifestRow }) {
  const fail = Object.values(row).reduce(
    (prev, value) => prev || value === "failed" || value === "disk-full",
    false
  );
  const running = Object.values(row).reduce(
    (prev, value) => prev || value === "running",
    false
  );
  const style = {
    backgroundColor: fail
      ? "lightcoral"
      : running
      ? "greenyellow"
      : "transparent",
  };

  return (
    <tr>
      <td style={style}>{row.logname}</td>
      <td style={style}>{row.logged_where}</td>
      <td style={style}>{row.mounted_where}</td>
      <td style={style}>{row.stored_where}</td>
      <td style={style}>{row.stored_status}</td>
      <td style={style}>{row.processed_how}</td>
      <td style={style}>{row.processed_status}</td>
      <td style={style}>{row.raw_upload_status}</td>
      <td style={style}>{row.processed_upload_status}</td>
    </tr>
  );
}

function ManifestTable({ rows }: { rows: ManifestRow[] }) {
  if (rows.length === 0) {
    return <p>No entries in manifest</p>;
  }
  return (
    <Table
      size="sm"
      responsive="md"
      bordered
      className="text-nowrap"
      style={{ fontFamily: "monospace", fontSize: "0.8rem" }}
    >
      <thead>
        <tr>
          <th>logname</th>
          <th>logged_where</th>
          <th>mounted_where</th>
          <th>stored_where</th>
          <th>stored</th>
          <th>processed_how</th>
          <th>processed</th>
          <th>raw_upload</th>
          <th>processed_upload</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <TableRow row={row} key={row.logname} />
        ))}
      </tbody>
    </Table>
  );
}

interface RouteParams {
  processorId: string;
}

export default function SingleManifest() {
  const { processorId } = useParams<RouteParams>();
  const manifest = useDocument<Manifest>(`processor-manifest/${processorId}`);

  if (!manifest) {
    return null;
  }

  return (
    <div id={processorId} className="manifest">
      <h3>
        {processorId} <a href={`http://${processorId}/`}>(tailscale)</a>
      </h3>
      <p>{moment(manifest.timestamp.toDate()).fromNow()}</p>
      <Row>
        <Col lg={9}>
          <ManifestTable rows={manifest.manifest ?? []} />
        </Col>
      </Row>
    </div>
  );
}
