import { DateTime } from "luxon";
import { useCallback, useContext, useMemo, useState } from "react";
import { GeoJSONLayer, Layer } from "react-mapbox-gl";

import Auth from "../../../Auth";
import { Status } from "../types";
import { statusToGeoJSON } from "../utils";
import { useHistory } from "./history";
import ScannerMarker from "./ScannerMarker";
import ScannerPopup from "./ScannerPopup";

interface ScannerHistoryProps {
  scannerId: string;
  start: DateTime | null;
  end: DateTime | null;
  instant: DateTime | null;
  setInstant: (instant: DateTime | null) => void;
  showPopup: boolean;
  setPopupId: (id: string | null) => void;
}
export default function ScannerHistory({
  scannerId,
  start,
  end,
  instant,
  setInstant,
  showPopup,
  setPopupId,
}: ScannerHistoryProps) {
  const { user } = useContext(Auth);

  const history = useHistory(scannerId, start, end, user!);

  const geojson = useMemo(() => statusToGeoJSON(history), [history]);

  // Index of the status message picked out by `instant` - the first status message
  // at or after `instant`.
  const instantIdx = useMemo(() => {
    if (instant === null) {
      return null;
    }
    const idx = history.findIndex(
      (s: Status) => s.timestamp.toMillis() >= instant.toMillis()
    );
    return idx === -1 ? null : idx;
  }, [instant, history]);

  // Record which feature is being hovered over.
  const [hoverId, setHoverId] = useState<number>();

  const popupStatus = useMemo(() => {
    if (history.length === 0) {
      return null;
    }
    return instantIdx === null
      ? history[history.length - 1]
      : history[instantIdx];
  }, [history, instantIdx]);

  const onMouseMove = useCallback((e) => {
    const style = e.target?.getCanvas()?.style;
    if (style) {
      style.cursor = "pointer";
    }
    setHoverId(e.features[0].id);
  }, []);

  const onMouseLeave = useCallback((e) => {
    const style = e?.target?.getCanvas()?.style;
    if (style) {
      style.cursor = "";
    }
    setHoverId(undefined);
  }, []);

  const onClick = useCallback(
    (e) => {
      const feature = e.features[0];
      let props: Record<string, any> = { scanner: scannerId };
      Object.entries(feature.properties).forEach(([k, v]) => {
        let val = v;
        try {
          val = JSON.parse(v as string);
        } catch {
          // Nothing.
        }
        return Object.assign(props, { [k]: val });
      });
      if (props.timestamp !== undefined) {
        setInstant(DateTime.fromISO(props.timestamp));
      }
      console.log(JSON.stringify(props, null, 2));
    },
    [scannerId, setInstant]
  );

  // Trajectory after `instant` is grey. Before and intersecting `instant` uses the 'color'
  // property of the feature. For trajectory intersecting `instant`, the the line thickness
  // is increased.
  const trajectoryColourExpr = [
    "case",
    ["==", ["id"], instantIdx ?? null],
    ["get", "color"],
    ["<=", ["get", "milliseconds"], instant?.toMillis() ?? Infinity],
    ["get", "color"],
    "darkgrey",
  ];
  const trajectoryWidthExpr = [
    "case",
    ["==", ["id"], hoverId ?? null],
    4,
    ["==", ["id"], instantIdx ?? null],
    4,
    1,
  ];

  return (
    <>
      {showPopup && popupStatus && (
        <ScannerPopup status={popupStatus} referenceTime={instant} />
      )}
      <GeoJSONLayer
        id={`${scannerId}-history`}
        data={geojson}
        circlePaint={{
          "circle-radius": ["interpolate", ["linear"], ["zoom"], 9, 1, 13, 3],
          "circle-color": trajectoryColourExpr,
        }}
        linePaint={{
          "line-color": trajectoryColourExpr,
          // Draw line thicker when it is being hovered over.
          "line-width": trajectoryWidthExpr,
        }}
        lineOnClick={onClick}
      />
      {/* Secondary layer that uses the same data source and renders the lines
       * thicker but almost transparent. This has the effect of making a
       * bigger target for the mouse. */}
      <Layer
        id={`${scannerId}-line-target`}
        sourceId={`${scannerId}-history`}
        before={`${scannerId}-history-line`}
        type="line"
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        paint={{
          "line-color": ["get", "color"],
          "line-width": 10,
          "line-opacity": 0.01,
        }}
      />
      {popupStatus && (
        <ScannerMarker
          status={popupStatus}
          referenceTime={instant || end || DateTime.now()}
          onClick={() => setPopupId(popupStatus.hostname)}
        />
      )}
    </>
  );
}
