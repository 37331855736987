import { DateTime } from "luxon";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Auth from "../../../Auth";
import { BaseMap } from "../../../components/BaseMap";
import TimeRangeSelector from "../../../components/TimeRangeSelector";
import { useCollection } from "../../../useFirebase";
import { Status } from "../types";
import { getPosition } from "../utils";
import InstantSelector from "./InstantSelector";
import ScannerHistory from "./ScannerHistory";
import ScannerMarker from "./ScannerMarker";
import ScannerPopup from "./ScannerPopup";
import ScannerTable from "./ScannerTable";

// Behaviour of Overview page
// --------------------------
// The UI has two modes - a 'live' mode where we display the last update from all
// scanners, and a 'history' mode for examining the historical trajectories of
// specific selected scanners.
//
// The UI is initially in 'live' mode and implicitly enters 'history' mode if a scanner
// is selected. We return to 'live' mode if no scanners are selected.
//
// In Live Mode
// ------------
// The time controls are not displayed and do not apply. A scanner marker will be grey
// if the most recent message is not within 3 minutes of `DateTime.now()`.
//
// In History Mode
// ---------------
// The time range filter and scrubber can be used to filter and scrub along scanner
// trajectories. The 'latest scanner updates' for non-selected scanners are not shown.
//
// For selected scanners, the status message used for the marker will be the first
// message after `instant`, or if `instant` is null or no such message exists, the last
// message after `start` and before `end`. If no such message exists, no marker is displayed.

// The `Overview` component (and all children) are re-rendered every time the scrubber
// is moved. To keep the scrubber responsive, re-renders must be fast - so we memoise
// the table, which is independent of the scrubber.
const MemoizedScannerTable = React.memo(ScannerTable);

export default function Overview() {
  const { user } = useContext(Auth);
  const scanners = useCollection<Status>("scanner-status", {
    operators: user!.admin ? undefined : user!.operators,
  });
  const [center, setCenter] = useState<[number, number]>([0.0, 0.0]);
  const [zoom, setZoom] = useState<[number]>([0.0]);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [popupId, setPopupId] = useState<string | null>(null);

  // Time range for the trajectory display. Default to the last day so that the first
  // history query is small. Note that this doesn't affect the scanner markers in 'live'
  // mode.
  const [start, setStart] = useState<DateTime>(DateTime.now().startOf("day"));
  const [end, setEnd] = useState<DateTime | null>(null);

  // Instant in time for the status/trajectory display.
  const [instant, setInstant] = useState<DateTime | null>(null);

  // Extract the current status for the scanner with `popupId`.
  const popupStatus: Status | null = useMemo(() => {
    let result = null;

    // If the popup scanner is currently selected, the popup will be rendered by
    // `ScannerHistory`, which picks the correct scanner `Status` given the selected time
    // range and instant. Otherwise we ignore the time filtering and render the popup using
    // the scanner's most recent status.
    if (popupId !== null && selectedIds.includes(popupId)) {
      return null;
    }

    scanners.forEach((s) => {
      if (s.id === popupId) {
        result = s;
      }
    });
    return result;
  }, [popupId, scanners, selectedIds]);

  // TODO: This zooms to the most recent status message, but should zoom to the current
  // marker for that scanner - which is determined by the time range and instant. This
  // change requires the scanner history.
  const zoomToScanner = useCallback((status: Status) => {
    setCenter(getPosition(status, "latest"));
    setZoom([14]);
  }, []);

  const handleScannerRowClick = useCallback(
    (s: Status) => {
      setSelectedIds((sids) => (sids.includes(s.id) ? sids : [...sids, s.id]));
      zoomToScanner(s);
      setPopupId(s.id);
    },
    [setSelectedIds, zoomToScanner, setPopupId]
  );

  // De-select all scanners if `start` is cleared - implicitly putting the UI back into
  // 'live' mode. If `start` is cleared, we set `start` to startOf("day") since `start`
  // is ignored in 'live' mode.
  const handleSetStart = useCallback(
    (start: DateTime | null) => {
      if (start === null) {
        setSelectedIds([]);
      }
      setStart(start || DateTime.now().startOf("day"));
    },
    [setStart, setSelectedIds]
  );

  const showOperator = useMemo(() => Boolean(user!.admin), [user]);

  const mode: "live" | "history" = useMemo(
    () => (selectedIds.length > 0 ? "history" : "live"),
    [selectedIds]
  );

  const referenceTime =
    mode === "live" ? DateTime.now() : instant || end || DateTime.now();

  return (
    <>
      <h2>Scanners</h2>
      <p>
        Click a checkbox to show/hide the scanner trajectory. Click on a row to
        both show the trajectory and zoom the map to that scanner.
      </p>
      <Row className="flex-grow-1 mb-2">
        <Col md={{ span: 3, order: 2 }}>
          <MemoizedScannerTable
            scanners={scanners}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            onScannerClick={handleScannerRowClick}
            showOperator={showOperator}
          />
        </Col>
        <Col className="d-flex flex-column pb-2">
          {/* Time controls are only displayed in 'history' mode. */}
          {mode === "history" && (
            <>
              <TimeRangeSelector
                start={start}
                end={end}
                setStart={handleSetStart}
                setEnd={setEnd}
              />
              <InstantSelector
                start={start}
                end={end || DateTime.now()}
                instant={instant}
                setInstant={setInstant}
              />
            </>
          )}
          <div id="map" className="flex-grow-1">
            <BaseMap
              center={center}
              zoom={zoom}
              onClick={() => setPopupId(null)}
            >
              <>
                {scanners.map((s: Status) => {
                  // In 'history' mode, `ScannerHistory` displays the markers of selected
                  // scanners, and non-selected scanner markers are not shown.
                  if (mode === "history") {
                    return null;
                  }

                  // TODO: May be more appropriate to render a partially transparent
                  // or greyed-out marker in the above cases, to indicate that data is
                  // available but has been filtered out (but then what do we do for
                  // markers shown inside `ScannerHistory`?).

                  return (
                    <ScannerMarker
                      key={s.id}
                      status={s}
                      referenceTime={referenceTime}
                      // Ignore the time controls when in 'live' mode - just use the
                      // current time to colour the markers.
                      onClick={() => setPopupId(s.id)}
                    />
                  );
                })}
                {popupStatus && (
                  <ScannerPopup
                    status={popupStatus}
                    referenceTime={referenceTime}
                  />
                )}
                {selectedIds.map((sid: string) => (
                  <ScannerHistory
                    key={`${sid}-history`}
                    scannerId={sid}
                    start={start}
                    end={end}
                    instant={instant}
                    setInstant={setInstant}
                    showPopup={popupId === sid}
                    setPopupId={setPopupId}
                  />
                ))}
              </>
            </BaseMap>
          </div>
        </Col>
      </Row>
    </>
  );
}
