import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Row, Column, CellProps } from "react-table";
import { DateTime } from "luxon";

import { Status } from "../types";
import {
  shortId,
  parseTime,
  summarizeStatus,
  variantForSummary,
} from "../utils";
import BaseTable from "../../../components/BaseTable";

interface SelectAllCheckboxProps {
  checked: boolean;
  indeterminate: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}
function SelectAllCheckbox({
  checked,
  indeterminate,
  onChange,
}: SelectAllCheckboxProps) {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [ref, indeterminate]);
  return (
    <input ref={ref} type="checkbox" checked={checked} onChange={onChange} />
  );
}

interface ScannerTableProps {
  scanners: Status[];
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
  onScannerClick: (scanner: Status) => void;
  showOperator: boolean;
}
export default function ScannerTable({
  scanners,
  selectedIds,
  setSelectedIds,
  onScannerClick,
  showOperator,
}: ScannerTableProps) {
  const handleCheckChange = useCallback(
    (scannerId: string, event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedIds((s) => (s.includes(scannerId) ? s : [...s, scannerId]));
      } else {
        setSelectedIds((s) => s.filter((sid) => sid !== scannerId));
      }
    },
    [setSelectedIds]
  );

  const handleSelectAll = useCallback(
    () =>
      setSelectedIds((sids) =>
        sids.length > 0 ? [] : scanners.map((s) => s.id)
      ),
    [scanners, setSelectedIds]
  );

  const columns: Column<Status>[] = useMemo(() => {
    const cols: Column<Status>[] = [
      {
        Header: () => (
          <SelectAllCheckbox
            checked={selectedIds.length > 0}
            indeterminate={
              selectedIds.length > 0 && selectedIds.length < scanners.length
            }
            onChange={handleSelectAll}
          />
        ),
        id: "select",
        accessor: "id",
        Cell: ({ value }: CellProps<Status, string>) => (
          <input
            type="checkbox"
            checked={selectedIds.includes(value)}
            onChange={(e) => handleCheckChange(value, e)}
            onClick={(e) => e.stopPropagation()}
          />
        ),
        disableSortBy: true,
      },
      { Header: "ID", accessor: (s: Status) => shortId(s.id) },
      {
        Header: "Status",
        id: "status",
        accessor: (s: Status) => parseTime(s.timestamp).toJSDate(),
        sortType: "datetime",
        Cell: (props: CellProps<Status, DateTime>) => {
          // Extract whole scanner status message (`s`) from the props.
          const {
            row: { original: s },
          } = props;
          const summary = summarizeStatus(s, DateTime.now());
          let classes = ["p-1 rounded"];
          if (!summary.stale) {
            classes.push(
              "text-white",
              "bg-" + variantForSummary(summary, false)
            );
          }
          const timeString = parseTime(s.timestamp).toRelative();
          return <small className={classes.join(" ")}>{timeString}</small>;
        },
      },
    ];
    if (showOperator) {
      // Insert operator column.
      cols.splice(2, 0, {
        Header: "Operator",
        accessor: "operator",
        Cell: ({ value }: { value: string }) => <small>{value}</small>,
      });
    }
    return cols;
  }, [selectedIds, scanners, handleSelectAll, handleCheckChange, showOperator]);

  // `getRowProps` is a callback that gets called for every row to set custom row
  // properties. In this case we want to set a `onClick` handler for each row.
  const getRowProps = useCallback(
    (row: Row<Status>) => {
      return {
        onClick: () => onScannerClick(row.original),
      };
    },
    [onScannerClick]
  );

  const initialState = useMemo(
    () => ({
      sortBy: [{ id: "status", desc: true }],
    }),
    []
  );

  return (
    <BaseTable
      data={scanners}
      columns={columns}
      getRowProps={getRowProps}
      initialState={initialState}
      autoResetSortBy={false}
    />
  );
}
